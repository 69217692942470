
.header-wrapper {
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 1rem 0;
    background-color: var(--bg-color-translucid);
    width: 100%;
}

.header-wrapper .header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
    margin-left: 1rem;
}

.header-wrapper .header-sidebar-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    margin-right: 1rem;
}

#header-sidebar {
    z-index: 9999;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20rem;
    border-radius: 5px 0 0 5px;
    background: linear-gradient(90deg, #0e0c27 0%, rgb(4 41 80) 50%, #0e0c27 100%);
    transition: width ease-in-out 0.5s;
}

.header-sidebar-head {
    padding: 1rem;
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--default-text-color);
}

.header-sidebar-body>div {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.header-sidebar-body>div>span {
    text-wrap: nowrap;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-sidebar-body form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    border: 2px solid var(--default-text-color);
    margin: 1rem;
    border-radius: 5px;
}

.header-sidebar-body form>input {
    padding: 0.5rem;
    border-radius: 5px;
}

.header-sidebar-body form>button {
    padding: 0.5rem;
    color: white;
    font-weight: bold;
}

.header-sidebar-logout-btn {
    color: white;
    font-weight: bold;
    background-color: var(--default-theme-color)!important;
}

.form-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid gray;
    font-weight: bold;
}

@media only screen and (max-width: 800px) {
    .logout-btn{
        display: none;
    }
}