
:root {
  --bg-color: #252424;
  --bg-color-translucid: #25242447;
  --default-text-color: #a5a5a5;
  --default-theme-color: #8907e4;
  min-height: 100vh;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, #0e0c27 0%, rgb(4 41 80) 50%, #0e0c27 100%);
  /*background-color: #070707;*/
  min-height:100vh;
}

input:focus, input:active, input:focus-visible {
  border: none;
}

.bg-default {
  background-color: var(--bg-color)!important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

canvas {
  width: 100%;
  height: 80px;
  border-radius: 5px;
  background-color: var(--bg-color);
}

.icon-btn {
  border: none;
  outline: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  width: fit-content;
  background: transparent;
}

.link-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--default-text-color);
  font-weight: bold;
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  text-align: left;
}

.btn {
  background-color: var(--default-theme-color);
  border: 1px solid gray;
  color: white;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: bold;
}

.btn:hover {
  border: 1px inset black;
}

.tag {
  background-color: var(--default-theme-color);
  padding: 3px;
  border-radius: 30%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #484747;
  border-radius: 0 5px 5px 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 5px;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1a1b1b;
}


.eq-style {
  margin-bottom: 0.2rem;
}

.close-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;

}

.close-btn svg{
  transition: 0.5s ease-out;
}

.separator-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  gap: 0.5rem;
}

.separator-text hr{
  flex: 1;
  border: 1px solid gray;
}

.d-flex {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}