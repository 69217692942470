
input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    padding: 1px 5px;
    cursor: pointer;
}

.player {
    max-width: 330px;
    display: flex;
    flex-direction: column;
}

.player-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.2rem;
    /*box-shadow: 0px 0px 8px 1px #000000bd;*/
    padding: 1rem;
    border-radius: 5px;
}

.player-control-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: gray;
    border-radius: 4px;
    border: black;
    outline: none;
    cursor: pointer;
    min-width: 36px;
    min-height: 28px;
    font-size: 14px;
    flex: 1;
}

.player-control-btn span {
    font-weight: bold;
    color: white;
}

.player-control-btn:active {
    border-style: inset;
    box-shadow: inset 1px 1px 5px black;
}

.player-control-btn.pressed {
    border-style: inset;
    box-shadow: inset 1px 1px 5px black;
}

.player-control-btn.pressed svg {
    transform: scale(0.9);
}

.player-control-btn:active svg {
    transform: scale(0.9);
}

.player-header {
    background-color: #252424;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 5px;
}

.player-control-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem;
    border: 1px inset #4b4b4bc2;
    border-radius: 4px;
    width: 100%;
}

.player-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.3rem;
    border: 1px inset #4b4b4bc2;
    border-radius: 4px;
    width: 100%;
}


.player-menu button:active {
    box-shadow: inset 1px 1px 5px black;
    transform: scale(0.98);
    transform: scale(0.98);
    color: white;
}

.player-menu button.active {
    box-shadow: inset 1px 1px 5px black;
    transform: scale(0.98);
    transform: scale(0.98);
    color: white;
}

.vr {
    border: 1px inset #4b4b4bc2;
    height: 20px;
    margin: 0 5px;
    width: 0px;
}

.player-playlist {
    color: var(--default-text-color);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 5px;
    background-color: #252424;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-height: 60vh;
    min-height: 50vh;
    margin: 0;
    flex: 1;
    max-width: 40rem;
    min-width: 290px;
    list-style: none;
    padding-inline-start: 1rem;
}

.player-playlist li{
    margin: 0.5rem;
}

.player-playlist p {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.player-info {
    background-color: #252424;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2rem;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 0.2rem;
    color: white;
    flex: 1;
}

.player-info .player-info-tags-container {
    margin-left: 0.5rem;
    max-width: 15rem;
    display: flex;
    justify-content: flex-start;
    gap: 0.2rem;
    align-items: center;
    flex-wrap: wrap;
    max-height: 10rem;
    overflow-y: auto;
}

.player-info a {
    color: var(--default-theme-color);
    text-decoration: none;
    border-bottom: 1px solid rgba(128, 128, 128, 0.63);
    white-space: nowrap;
    overflow-x: hidden;
    max-width: 100%;
}

.player-info p {
    display: flex;
    white-space: nowrap;
    overflow-x: hidden;
    margin: 0.2rem 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.63);
    width: 100%;
}

.player-info img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    border: 1px inset black;
    padding: 0.1rem;
}

.player-playlist-wrapper {
    border-radius: 5px;
    background-color: #252424;
    flex: 1 1;
    max-width: 40rem;
    width: 100%;
}

.player-playlist-wrapper>.player-playlist-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0.2rem;
    border-bottom: 1px inset black;
}

.player-playlist-wrapper>.player-playlist-pagination>button{
    padding: 0.2rem 0.5rem;
}

.player-playlist-header {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.5rem 0.2rem;
    border-bottom: 1px inset black;
    align-items: center;
}

.player-playlist-radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    width: 97%;
    padding: 0.5rem 0;
    background-color: var(--bg-color-translucid);
}

.player-playlist-radio div:first-child {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
}

.player-playlist-radio div:first-child button {
    width: 85%;
}

.player-playlist-radio:hover {
    background-color: #8080803b;
}

.scroll-container {
    overflow: hidden;
    width: 100%;
    border-bottom: 1px inset black;
    padding-bottom: 0.5rem;
}

.scroll-text {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    white-space: nowrap;

    -moz-animation: my-animation 15s linear infinite;
    -webkit-animation: my-animation 15s linear infinite;
    animation: my-animation 15s linear infinite;
}

.playlist-filterBy-dropdown {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
}

/* for Firefox */
@-moz-keyframes my-animation {
    from { -moz-transform: translateX(100%); }
    to { -moz-transform: translateX(-400%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(100%); }
    to { -webkit-transform: translateX(-400%); }
}

@keyframes my-animation {
    from {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-400%);
    }

}
/*.player-playlist li.selected.scroll-anim p{*/
/*    overflow-x: unset;*/
/*    text-overflow: unset;*/
/*}*/


/*.player-playlist li.selected.scroll-anim {*/
/*    animation: floatText 10s infinite linear;*/
/*    !*padding-left: 100%; !*Initial offset*!*!*/
/*}*/

@keyframes floatText {
    to {
        transform: translateX(-150%);
    }
}

.player-container li.selected {
    color: #195ac4!important;
}

.player-container li.selected .link-btn {
    color: #195ac4!important;
}

.progress-bar {
    display: flex;
    flex-direction: column;
}

.progress-bar>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 0.3rem;
    color: #6a6a6a;
}

.progress-bar input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.progress-bar input[type="range"]::-webkit-slider-runnable-track {
    height: 1.2rem;
    border: 1px inset black;
    background-color: #0000008a;
}

/******** Firefox ********/
.progress-bar input[type="range"]::-moz-range-track {
    height: 1.2rem;
    border: 1px inset black;
    border-radius: 5px;
    background-color: #0000008a;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.progress-bar input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -0.5rem; /* Centers thumb on the track */
    background: linear-gradient(to right, #181818, #4343fa, #181818);
    height: 2rem;
    width: 1rem;
}

/***** Thumb Styles *****/
/***** Firefox *****/
.progress-bar input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -0.5rem; /* Centers thumb on the track */
    background: linear-gradient(to right, #181818, #5e5d5d, #181818);
    height: 2rem;
    width: 1rem;
}

@media only screen and (max-width: 800px){
    .player-container {
        width: 85vw;
        margin: auto;
    }
    .player-header{
        flex-direction: column;
        align-items: flex-start;
    }
    .player-playlist-header {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 1000px) {
    .player{
        display: none;
    }
    .player.mobile-show{
        display: flex;
    }
    .player-playlist-wrapper {
        display: none;
    }
    .player-playlist-wrapper.mobile-show{
        display: block;
    }

    .playlist-filterBy-dropdown {
        width: 247px;
        justify-content: space-between;
    }
    .player-playlist-wrapper>.player-playlist-pagination {
        gap: 22px;
    }

    .player-playlist-header {
        position: relative;
    }

    .playlist-header-fav-container {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
    }

    .playlist-header-fav-container>button {
        margin: 0;
    }
}