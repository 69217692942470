.mobile-header-buttons {
    display: none;
    padding: 0.5rem 1rem;
    background-color: transparent;
}

.mobile-header-buttons>button{
    min-height: 2rem;
    background-color: white;
    color: black;
    border-radius: 5px;
    font-weight: bold;
    flex: 1 1;
    gap: 0.2rem;
}

.mobile-header-buttons>.icon-btn.btn-outline{
    background-color: var(--bg-color);
    border: 1px solid white;
    color: white;
}

@media only screen and (max-width: 1000px) {
    .mobile-header-buttons {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
    }
}

@media only screen and (max-width: 375px) {
    .mobile-header-buttons button>span {
        display: none;
    }
}