.range-wrapper input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.range-wrapper input[type="range"]::-webkit-slider-runnable-track {
    height: 1.2rem;
    border: 1px inset black;
    background-color: #0000008a;
}

/******** Firefox ********/
.range-wrapper input[type="range"]::-moz-range-track {
    height: 1.2rem;
    border: 1px inset black;
    border-radius: 5px;
    background-color: #0000008a;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.range-wrapper input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: 0px; /* Centers thumb on the track */
    background: linear-gradient(to right, #181818, #5e5d5d, #181818);
    height: 1rem;
    width: 2rem;
}

/***** Thumb Styles *****/
/***** Firefox *****/
.range-wrapper input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -1px; /* Centers thumb on the track */
    background: linear-gradient(to right, #181818, #5e5d5d, #181818);
    height: 1rem;
    width: 2rem;
}